<template>
    <component :is="initialSetting"></component>
</template>

<script>
import operationLimitForm from './operation_limits/form'
import operationPeriodForm from './operation_periods/form'
import panelVersionForm from './version_management/form'
import scheduleProcessRunnerForm from './schedule_process_runners/form'
import settingExcessTimeInPlantForm from './setting_excess_time_in_plants/form'
import checklistManagerForm from './checklist_manager/checklistTable'
import checkTable from './service_plannings_checklist/checkTable'
export default {
  name: 'InitialSettingDetail',
  components: {
    operation_limits: operationLimitForm,
    operation_periods: operationPeriodForm,
    panel_versions: panelVersionForm,
    schedule_process_runners: scheduleProcessRunnerForm,
    setting_excess_time_in_plants: settingExcessTimeInPlantForm,
    checklist_manager: checklistManagerForm,
    service_plannings_checklist: checkTable,
  },
  data () {
    return {
      initialSetting: this.$route.params.table,
    }
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }

  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>

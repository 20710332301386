<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getData"
      :sortByOptions="sortByOptions"
      @searchValue="searchValue = $event"
      @sortByValue="sortByValue = $event"
      @sortDirectionValue="sortDirectionValue = $event"
      @perPageValue="perPageValue = $event"
      @currentPageValue="currentPageValue = $event"
    />

    <!--Botonera  -->
    <div class="row justify-content-center">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`"
             ref="modal-form"
             size="lg"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          ref="rule-Form"
          :model="form"
          :rules="rules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="operationPeriod" label="Periodo de Operación" prop="operationPeriod" >
            <a-select v-model="form.operation_period_id" placeholder="Seleccionar el Periodo de Operación">
              <a-select-option  v-for="option in operation_periods" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                De: {{ moment(option.initial_period_dated_at).format('DD-MM-YYYY') }} a {{ moment(option.final_period_dated_at).format('DD-MM-YYYY') }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="containerType" label="Tipo de Contenedor" prop="containerType" >
            <a-select v-model="form.container_type_id" placeholder="Seleccionar el Tipo de Contenedor">
              <a-select-option  v-for="option in container_types" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="containerCapacity" label="Capacidad del Contenedor" prop="containerCapacity" >
            <a-input v-model.trim="form.container_capacity" class="text-center"/>
          </a-form-model-item>
          <a-form-model-item ref="containerQuantity" label="Cantidades de Contenedores" prop="containerQuantity" >
            <a-input v-model.trim="form.container_quantities" class="text-center"/>
          </a-form-model-item>
          <a-form-model-item label="Total">
            <a-input v-model="calculateTotal" class="text-center" disabled/>
          </a-form-model-item>
          <div class="row justify-content-center" v-show="!load">
            <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
            <responsive-button v-if="this.id !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"/>
          </div>
        </a-form-model>
        <!-- Form with Model -->

        <!-- Spinner -->
        <my-spinner :load="load"/>

      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- Tabla -->
    <detail-catalog-table :tableObject="tableObject"
      @Modal="OpenModal" @Filter="getData"
      @searchInput="searchInput = $event"
      class="mt-3"
    >
      <div slot="details" slot-scope="record">
        <strong><b-icon icon="box"></b-icon> Tipo de Contenedor: </strong> {{ record.record.containerTypeRelation.code }} - {{ record.record.containerTypeRelation.name }}
        <br>
        <strong><b-icon icon="calendar-date"></b-icon> Períodos de Operación: </strong> Programado: {{ moment(record.record.operationPeriodRelation.last_day_programed_at).format('DD-MM-YYYY') }} <br>
        Fecha Inicial: {{ moment(record.record.operationPeriodRelation.initial_period_dated_at).format('DD-MM-YYYY') }} <br>
        Fecha Final: {{ moment(record.record.operationPeriodRelation.final_period_dated_at).format('DD-MM-YYYY') }}
      </div>
    </detail-catalog-table>
  </div>
</template>

<script>
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailCatalogTable from '../../catalogs/components/table'
import DetailCatalogBar from '../../catalogs/components/searchBar'
import { notification, Modal } from 'ant-design-vue'
export default {
  name: 'operationLimitForm',
  components: {
    DetailCatalogTable,
    DetailCatalogBar,
  },
  data() {
    return {
      // <!-- Constantes -->
      resource: 'operationLimits',
      resourceName: 'Límites de Operación',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'initial_settings',
          breadcrumbName: 'Configuraciones',
          aIcon: '',
        },
        {
          breadcrumbName: 'Límites de Operación',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Capacidad del Contenedor',
            dataIndex: 'container_capacity',
            key: 'container_capacity',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.container_capacity.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Cantidades de Contenedores',
            dataIndex: 'container_quantities',
            key: 'container_quantities',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.container_quantities.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Detalles',
            dataIndex: '',
            key: 'detail',
            scopedSlots: {
              customRender: 'myCustomColumns',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'container_capacity',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'container_quantities', text: 'Cantidades de Contenedores' },
        { value: 'container_capacity', text: 'Capacidad del Contenedor' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // <!-- Form with Model -->
      id: '0',
      form: {
        operation_period_id: undefined,
        container_type_id: undefined,
        container_capacity: '',
        container_quantities: '',
      },
      // Relaciones
      relationships: {
        operationPeriod: {
          data: { id: 0, type: 'operationPeriods' },
        },
        containerType: {
          data: { id: 0, type: 'containerTypes' },
        },
      },
      // arrays selects
      operation_periods: [],
      container_types: [],
      // Reglas de validación
      rules: {
        operation_period_id: [
          { required: true, message: 'El campo Periodo de Operación es obligatorio.', trigger: 'blur' },
        ],
        container_type_id: [
          { required: true, message: 'El campo Tipo de Contenedor es obligatorio.', trigger: 'blur' },
        ],
        container_capacity: [
          { required: true, message: 'El campo Capacidad del Contenedor es obligatoria.', trigger: 'blur' },
          { min: 2, max: 50, message: 'El campo Capacidad del Contenedor debe contener entre 2 y 20 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Capacidad del Contenedor debe ser de tipo texto.', trigger: 'blur' },
        ],
        container_quantities: [
          { required: true, message: 'El campo Cantidades de Contenedores es obligatorio.', trigger: 'blur' },
          { min: 1, max: 50, message: 'El campo Cantidades de Contenedores debe contener entre 3 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Cantidades de Contenedores debe ser de tipo texto.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
    calculateTotal() {
      return this.form.container_capacity * this.form.container_quantities
    },
  },
  methods: {
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      this.tableObject.loading = true
      this.$store.dispatch('jv/get', [this.resource, {
        params: {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          'filter[search]': `${this.searchValue}`,
          sort: `${this.sortDirection}${this.sortValue}`,
        },
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Get array selects data  -->
    getOperation_periods() {
      this.$store.dispatch('jv/get', ['operationPeriods', {
        params: {
          sort: 'last_day_programed_at',
        },
      }])
        .then(response => {
          const tableData = response
          this.operation_periods = objectArray(tableData)
        })
    },
    getContainer_types() {
      this.$store.dispatch('jv/get', ['containerTypes', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.container_types = objectArray(tableData)
        })
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      this.getOperation_periods()
      this.getContainer_types()
      this.getOperationPeriods()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.id = '0'
        this.form.code = ''
        this.form.operation_period_id = undefined
        this.form.container_type_id = undefined
        this.form.container_capacity = ''
        this.form.container_quantities = ''
      } else {
        // Update case
        this.id = data.jsonApi.id
        this.form.code = data.code
        this.form.operation_period_id = data.operation_periodRelationKey
        this.form.container_type_id = data.container_typeRelationKey
        this.form.container_capacity = data.container_capacity
        this.form.container_quantities = data.container_quantities
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.id = '0'
      this.resetForm()
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'
          // build the relationships with the form data
          this.relationships.operationPeriod.data.id = this.form.operation_period_id
          this.relationships.containerType.data.id = this.form.container_type_id
          if (this.id === '0') {
            // Store Object
            formObject = {
              container_capacity: `${this.form.container_capacity}`,
              container_quantities: `${this.form.container_quantities}`,
              jsonApi: {
                type: this.resource,
                relationships: this.relationships,
              },
            }
            // Store Object
          } else {
            // Update Object
            action = 'patch'
            formObject = {
              container_capacity: `${this.form.container_capacity}`,
              container_quantities: `${this.form.container_quantities}`,
              jsonApi: {
                type: this.resource,
                id: this.id,
                relationships: this.relationships,
              },
            }
            // Update Object
          }
          // Reset relationships
          this.resetRelathionships()
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getData()
              this.load = false
              this.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetRelathionships() {
      this.relationships = {
        operationPeriod: {
          data: { id: 0, type: 'operationPeriods' },
        },
        containerType: {
          data: { id: 0, type: 'containerTypes' },
        },
      }
    },
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>

<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getData"
                        :sortByOptions="sortByOptions"
                        @searchValue="globalValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />

    <!--Botonera  -->
    <div class="row justify-content-center">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`"
            ref="modal-form"
            size="lg"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          ref="rule-Form"
          :model="form"
          :rules="rules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="workCenter" label="Centro de Trabajo" prop="workCenter" >
            <a-select v-model="form.work_center_id" placeholder="Seleccionar el Centro de Trabajo">
              <a-select-option  v-for="option in work_centers" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <div>
            <label for="example-datepicker3">Último día Programado</label>
            <b-form-datepicker id="example-datepicker3" v-model="form.last_day_programed_at" class="mb-2"></b-form-datepicker>
          </div>
          <br>
          <div>
            <label for="example-datepicker">Período Inicial</label>
            <b-form-datepicker id="example-datepicker" v-model="form.initial_period_dated_at" class="mb-2"></b-form-datepicker>
          </div>
          <br>
          <div>
            <label for="example-datepicker2">Período  Final</label>
            <b-form-datepicker id="example-datepicker2" v-model="form.final_period_dated_at" class="mb-2"></b-form-datepicker>
          </div>
          <div class="row justify-content-center" v-show="!load">
            <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" fontAwesome="fa fa-save" @ClickEvent="onSubmit"/>
            <responsive-button v-if="this.id !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"/>
          </div>
        </a-form-model>
        <!-- Form with Model -->

        <!-- Spinner -->
        <my-spinner :load="load"/>

      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- Tabla -->
    <operation-periods-table :tableObject="tableObject"
      @Modal="OpenModal" @Filter="getData"
      @searchInput="searchInput = $event"
    >
      <div slot="workCenterDetails" slot-scope="record">
        <strong>
          {{ record.record.workCenterRelation.code }} - {{ record.record.workCenterRelation.name }}
        </strong>
      </div>
      <div slot="lastDayProgramedAtDetails" slot-scope="record">
        {{ moment(record.record.last_day_programed_at).format("MMMM Do YYYY") }}
      </div>
      <div slot="initialPeriodDatedAtDetails" slot-scope="record">
        {{ moment(record.record.initial_period_dated_at).format("MMMM Do YYYY") }}
      </div>
      <div slot="finalPeriodDatedAtDetails" slot-scope="record">
        {{ moment(record.record.final_period_dated_at).format("MMMM Do YYYY") }}
      </div>
    </operation-periods-table>
  </div>
</template>

<script>
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import OperationPeriodsTable from '../../auxiliar_components/table'
import DetailCatalogBar from '../../catalogs/components/searchBar'
import { notification, Modal } from 'ant-design-vue'
import 'moment/locale/es'
export default {
  name: 'operationPeriodForm',
  components: {
    OperationPeriodsTable,
    DetailCatalogBar,
  },
  data() {
    return {
      // <!-- Constantes -->
      initial_period_dated_at: '',
      final_period_dated_at: '',
      last_day_programed_at: '',
      resource: 'operationPeriods',
      resourceName: 'Períodos de Operación',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'initial_settings',
          breadcrumbName: 'Configuraciones',
          aIcon: '',
        },
        {
          breadcrumbName: 'Períodos de Operación',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Centro de Trabajo',
            dataIndex: 'work_center_name',
            key: 'work_center_name',
            class: 'text-center',
            scopedSlots: {
              customRender: 'workCenter',
            },
          },
          {
            title: 'Último día Programado',
            dataIndex: 'last_day_programed_at',
            key: 'last_day_programed_at',
            class: 'text-center',
            scopedSlots: {
              customRender: 'lastDayProgramedAt',
            },
          },
          {
            title: 'Período Inicial',
            dataIndex: 'initial_period_dated_at',
            key: 'initial_period_dated_at',
            class: 'text-center',
            scopedSlots: {
              customRender: 'initialPeriodDatedAt',
            },
          },
          {
            title: 'Período Final',
            dataIndex: 'final_period_dated_at',
            key: 'final_period_dated_at',
            class: 'text-center',
            scopedSlots: {
              customRender: 'finalPeriodDatedAt',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      globalValue: '',
      sortByValue: '',
      sortByDefaultValue: 'last_day_programed_at',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'workCenter', text: 'Centros de Trabajo' },
        { value: 'last_day_programed_at', text: 'Último día Programado' },
        { value: 'initial_period_dated_at', text: 'Período Inicial' },
        { value: 'final_period_dated_at', text: 'Período Final' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // <!-- Form with Model -->
      id: '0',
      form: {
        work_center_id: undefined,
        last_day_programed_at: '',
        initial_period_dated_at: '',
        final_period_dated_at: '',
      },
      // Relaciones
      relationships: {
        workCenter: {
          data: { id: 0, type: 'workCenters' },
        },
      },
      // arrays selects
      work_centers: [],
      // Reglas de validación
      rules: {
        work_center_id: [
          { required: true, message: 'El campo Centro de Trabajo es obligatorio.', trigger: 'blur' },
        ],
        last_day_programed_at: [
          { required: true, message: 'El campo Último día Programado es obligatoria.', trigger: 'blur' },
          { min: 2, max: 50, message: 'El campo Último día Programado debe contener entre 2 y 50 caracteres.', trigger: 'blur' },
          { type: 'date', message: 'El campo Último día Programado debe ser de tipo fecha.', trigger: 'blur' },
        ],
        initial_period_dated_at: [
          { required: true, message: 'El campo Período  Inicial es obligatoria.', trigger: 'blur' },
          { min: 2, max: 20, message: 'El campo Período  Inicial debe contener entre 2 y 20 caracteres.', trigger: 'blur' },
          { type: 'date', message: 'El campo Período  Inicial debe ser de tipo fecha.', trigger: 'blur' },
        ],
        final_period_dated_at: [
          { required: true, message: 'El campo Período  Final es obligatoria.', trigger: 'blur' },
          { min: 2, max: 20, message: 'El campo Período  Final debe contener entre 2 y 20 caracteres.', trigger: 'blur' },
          { type: 'date', message: 'El campo Período  Final debe ser de tipo fecha.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      this.tableObject.loading = true
      this.$store.dispatch('jv/get', [this.resource, {
        params: {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          'filter[global]': `${this.globalValue}`,
          sort: `${this.sortDirection}${this.sortValue}`,
        },
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Get array selects data  -->
    getWork_centers() {
      this.$store.dispatch('jv/get', ['workCenters', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.work_centers = objectArray(tableData)
        })
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      this.getWork_centers()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.id = '0'
        this.form.work_center_id = undefined
        this.form.last_day_programed_at = ''
        this.form.initial_period_dated_at = ''
        this.form.final_period_dated_at = ''
      } else {
        // Update case
        this.id = data.jsonApi.id
        this.form.last_day_programed_at = data.last_day_programed_at
        this.form.initial_period_dated_at = data.initial_period_dated_at
        this.form.final_period_dated_at = data.final_period_dated_at
        this.form.work_center_id = data.workCenterRelationKey
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.id = '0'
      this.resetForm()
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'
          // build the relationships with the form data
          this.relationships.workCenter.data.id = this.form.work_center_id
          if (this.id === '0') {
            // Store Object
            formObject = {
              last_day_programed_at: `${this.form.last_day_programed_at}`,
              initial_period_dated_at: `${this.form.initial_period_dated_at}`,
              final_period_dated_at: `${this.form.final_period_dated_at}`,
              jsonApi: {
                type: this.resource,
                relationships: this.relationships,
              },
            }
            // Store Object
          } else {
            // Update Object
            action = 'patch'
            formObject = {
              last_day_programed_at: `${this.form.last_day_programed_at}`,
              initial_period_dated_at: `${this.form.initial_period_dated_at}`,
              final_period_dated_at: `${this.form.final_period_dated_at}`,
              jsonApi: {
                type: this.resource,
                id: this.id,
                relationships: this.relationships,
              },
            }
            // Update Object
          }
          // Reset relationships
          this.resetRelathionships()
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getData()
              this.load = false
              this.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetRelathionships() {
      this.relationships = {
        workCenter: {
          data: { id: 0, type: 'workCenters' },
        },
      }
    },
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>

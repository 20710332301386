<template>
  <div>
    <div class="air__utils__heading">
      <h5>Checklist de Planeación de Servicios</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!-- Tabla -->
    <a-table
      :scroll="{x:1300}"
      bordered
      :dataSource="tableObject.data"
      :columns="tableObject.columns"
      :loading="tableObject.loading"
      :row-key="record => record.code"
      :pagination="tableObject.pagination"
      @change="getData"
    >
      <div slot="filterDropdown"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
           class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar por ${column.title}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar</a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar</a-button>
      </div>
      <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
      <template slot="customRender" slot-scope="text">
        <span v-if="searchText">
          <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
            <template v-else>{{fragment}}</template>
          </template>
        </span>
        <template v-else>{{text}}</template>
      </template>
      <template slot="myCustomColumns" slot-scope="text, record">
        <slot name="details" :record="record"/>
      </template>
      <div slot="detail" slot-scope="data">
        <strong>Etapa: </strong> <span>{{data.servicePlanningsSubstages.name}}</span>
        <br>
        <p>{{data.servicePlanningsSubstages.description}}</p>
        <br>
        <strong v-if="data.need_authorizer" class="text-primary">Necesita Autorización</strong>
      </div>
      <!-- Columnas Personalizadas -->
      <a slot="action" slot-scope="data">
        <responsive-button variant="secondary"
                           pill size="sm" responsive="md"
                           text="Ir" icon="arrow-right-circle"
                           :to="{name: 'service_plannings_checklist', params: { check: data.jsonApi.id }}"
        />
      </a>
    </a-table>
    <!-- Tabla -->
  </div>
</template>

<script>
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
export default {
  name: 'checkTable',
  mixins: [getGeneralMixin],
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'initial_settings',
          breadcrumbName: 'Configuraciones',
          aIcon: '',
        },
        {
          breadcrumbName: 'Checklist de Planeación de Servicios',
          aIcon: '',
        },
      ],
      // <!-- Table -->
      searchText: '',
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Información',
            dataIndex: '',
            key: 'detail',
            scopedSlots: {
              customRender: 'detail',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            class: 'text-center',
            key: 'x',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        perPageValue: 15,
        currentPageValue: 1,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
      },
      // <!-- Table -->
    }
  },
  methods: {
    async getData(paginationEvent = {}) {
      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.tableObject.currentPageValue}`,
        'page[size]': `${this.tableObject.perPageValue}`,
        include: 'servicePlanningsSubstages',
      }
      await this.getGeneralAntTable(paginationEvent, 'checks', params, this.tableObject)
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped>

</style>
